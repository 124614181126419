import React, { Component } from 'react';
//import React from 'react';
import PropTypes from 'prop-types';
import ChatBot,{ Loading } from 'react-simple-chatbot';
import botAvatar2 from './assets/images/bot.jpg'
//import botAvatar2 from './assets/images/genbaneko.jpg'
import botAvatar from './assets/images/Hashimoto1_2.jpg'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import './accordion.css';
import './chatbot.css';
import { Anchorme } from 'react-anchorme'

let outmsg ="";
let flg=0;


class DBPedia extends Component {

  items = [];

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      result: '',
      trigger: false,
    };

    this.triggetNext = this.triggetNext.bind(this);
  }

  triggetNext() {
    this.setState({ trigger: true }, () => {
      this.props.triggerNextStep();
    });
  }

  postText = (value) => {      
    fetch('https://api.slc-chatbot.click/api/cbjinji', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: value
      })
    })
      .then(response => response.json())
      .then(data => {this.setState({status: data.status, outmsgs: data.outmsgs});this.items=data.outmsgs;})
  }

  componentDidMount(){

    const self = this;
    const { steps } = this.props;

    const wait = async (ms) => new Promise(resolve => setTimeout(resolve, ms));                  
    const main = async () => {
      console.log(new Date());
      this.postText(steps.search.value)
      await wait(1500); // 待つ
      self.setState({ loading: false });
      console.log(new Date());
      this.triggetNext()
    };

    main();
  }

  render() {
    const { trigger, loading, result } = this.state;

    return (
      <div className="dbpedia">
        { loading ? <Loading /> : <DispAccordion items={this.items}/> }
      </div>
    );
  }
}

DBPedia.propTypes = {
  steps: PropTypes.object,
  triggerNextStep: PropTypes.func,
};

DBPedia.defaultProps = {
  steps: undefined,
  triggerNextStep: undefined,
};

const CustomLink = (props) => {
  return (
    <a {...props} >こちら</a>
  )
}

class DispAccordion extends Component {
  render() {
    return (
      <div>
        質問はこちらですか？
        <Accordion allowMultipleExpanded>
          {this.props.items.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  {item.question}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Anchorme linkComponent={CustomLink} target="_blank" rel="noreferrer noopener">
                  {item.answer}
                </Anchorme>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    ) 
  }
};

const Header = ({ toggleFloating }) => (
  <div>
    <div className="rsc-header">
      <h2 className="rsc-header-title">はい、人事部です！</h2>
      <a className="rsc-header-close-button" href="/" onClick={() => toggleFloating(false) }>
        <svg height="24" viewBox="0 0 24 24" width="24" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </a>
    </div>
    <div className='contact-header'>
      お問い合わせ一覧は<a className='contact-link' target="_blank" rel="noreferrer" href="https://www.google.com/">こちら</a>
    </div>
  </div>
)

export default class chatbot extends Component  {
    
    constructor(props){
      super(props);
      this.state = {
        status: '',
        text:'',
        outmsgs:'',
        opened: false
      };
      this.toggleFloating = this.toggleFloating.bind(this);
    }

    toggleFloating = (value) => {
      this.setState({ opened : value }); 
    }
    
    updateState(state){
      this.setState(state)
    }
    
    testtest(){
      let str = ["１つ目の候補\n 類似度：0.801082849532323\r\n 「勤務場所で行う健康診断は、勤務時間になるか」という質問だと理解しました。\r\n\r\n ーーー 回答 ーーー\r\n 勤務時間扱いです。",
      "２つ目の候補\n 類似度：0.801082849532323\r\n 「あああああああ」という質問だと理解しました。\r\n ーーー 回答 ーーー\r\n アアアアアア",
      "３つ目の候補\n 類似度：0.801082849532323\r\n 「いいいいいい」という質問だと理解しました。\r\n ーーー 回答 ーーー\r\n イイイイイイイイ。",
      "",""];
         return str;
    }
     
    render() {

        return (

            <ChatBot
            headerComponent={<Header toggleFloating={this.toggleFloating}/>}
            opened={this.state.opened}
            toggleFloating={this.toggleFloating}
            width="1000px"
            floating={true}
            botAvatar={botAvatar}
            userAvatar={botAvatar2}
//            bubbleStyle={{ width: '70%',lineHeight: '10', whiteSpace: 'pre-line'}}
            bubbleStyle={{ whiteSpace: 'pre-line'}}
            steps={[
              {
                id: '1',
                message: 'こんにちは！気軽に質問してくださいね！',
                trigger: 'search',
              },
              {
                id: 'search',
                user: true,
                placeholder: '質問を入力してください',
                validator:(value) => {
                  if (value==='') {
                    return '質問を入力してください'
                  }
                  this.setState({
                    text: value
                  });
                  return true;
                },
                trigger: '3',
              },
              {
                id: '3',
              message: '「{previousValue}」ですね。しばらくお待ちください',
//                message: ()=> { this.outmsg=""; this.postText(this.state.text); return '「{previousValue}」ですね。しばらくお待ちください';},
              trigger: 'zzz',
              },
              {
                id: 'zzz',
                component: <DBPedia />,
                waitAction: true,
                trigger: '4',
              },
              {
                id: '4',
                message: '問題は解決しましたか？',
                trigger: 'option1',
              },

              {
                id: '666',
                component: <div>aaaaa: </div>,
            //                component:(<div>wakaran:{this.myfunc()}</div>),
            //message:() => { return this.outmsg.toString(); },
            //component:<Post parent_state={this.outmsg.toString()} />,
                asMessage: true,
                trigger: 'finish?',
              },

            //              {
            //                id: '4',
            //                component: <Post updateState={this.updateState.bind(this)} parent_state={this.state} paramvalue={this.state.text} />,
            //                asMessage: true,
            //                trigger: 'finish?',
            //              },


              {
                id: 'finish?',
                message: '問題は解決しましたか？',
                trigger: 'option1',
              },

              {
                id: 'else',
                message: 'そのほかの質問はありますか？',
                trigger: 'option2',
              },

              {
                id:'end',
                message: 'ご利用ありがとうございました',
                hideInput:true,
                end:true,
              },
              {
                id:'error',
                message: '連絡先：XXXXXXX',
                hideInput:true,
                end:true,
              },

              {
                id: 'more',
                message: '質問内容を入力してください',
                trigger: 'search',
              },

              //Option Step
              {
                id:'option1',
                options:[
                    {value:'はい',label:'はい',trigger:'else'},
                    {value:'いいえ',label:'いいえ',trigger:'more'},
                ],
                hideInput:true,
              },

              {
                id:'option2',
                options:[
                    {value:'はい',label:'はい',trigger:'more'},
                    {value:'いいえ',label:'いいえ',trigger:'end'},
                ],
                hideInput:true,
              },

              //Update Step -- update先を実行して、新しいTriggerにすすむ時に使う
              {
                id: 'check',
                update: 'more',
                trigger: 'option1',
              },

                ]}
            />
          )}
}
